import Header from "./components/Header";
import { Outlet } from "react-router-dom";
import Footer from "./components/Footer";
// import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import "./styles/App.css";

function App() {
     return (
          <div className="App">
               <header></header>
               <outlet></outlet>
               <footer></footer>
          </div>
     ) ;
}

export default App ;
