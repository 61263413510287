import { useEffect } from "react";
import "../styles/CheckOut.css";
import donation from "../data/donation.json";
import {
     PayPalScriptProvider,
     PayPalButtons,
     usePayPalScriptReducer,
} from "@paypal/react-paypal-js";

const currency = "EUR";
const style = { layout: "vertical", color: "silver" };

// Custom component to wrap the PayPalButtons and handle currency changes
const ButtonWrapper = ({ amounts, currency, showSpinner }) => {
     // usePayPalScriptReducer can be use only inside children of PayPalScriptProviders
     // This is the main reason to wrap the PayPalButtons in a new component
     const [{ options, isPending }, dispatch] = usePayPalScriptReducer();

     useEffect(() => {
          dispatch({
               type: "resetOptions",
               value: {
                    ...options,
                    currency: currency,
               },
          });
     }, [currency, showSpinner]);

     return (
          <>
               {showSpinner && isPending && <div className="spinner"></div>}
               <paypalbuttons style="{style}" disabled="{false}" forceReRender="{[amounts," currency,="" style]}="" fundingSource="{undefined}" createOrder="{(data," actions)=""> {
                         return actions.order
                              .create({
                                   purchase_units: [
                                        {
                                             amount: {
                                                  currency_code: currency,
                                                  value: amounts,
                                             },
                                        },
                                   ],
                              })
                              .then((orderId) => {
                                   // Your code here after create the order
                                   return orderId;
                              });
                    }}
                    onApprove={function (data, actions) {
                         return actions.order.capture().then(function () {
                              // Your code here after capture the order
                         });
                    }}
               />
          </>
     );
};

export default function CheckOut() {
     // const [openButton, setOpenButton] = useState(true);

     return (
          <details className="donate-container">
               <summary>
                    <p style="{{" textTransform:="" "uppercase",="" }}="">
                         donner
                    </p>
               </summary>
               <div className="paypal-container">
                    <span className="paypal-text">
                         Votre soutien est essentiel pour moi ! Chaque don, quelle que soit sa
                         que ce soit, aide à alimenter ma créativité et ma passion pour la
                         ma créativité et ma passion pour la musique. Grâce à vos généreuses
                         généreuses, je peux continuer à créer de la
                         et passionnante tout en conservant ma liberté artistique. Vos dons
                         vos dons serviront à financer l'équipement du studio et à
                         couvrir les coûts de production de la musique, ce qui me permet d'offrir directement de la musique de haute qualité avec une précieuse indépendance.
                         musique de haute qualité directement avec une précieuse indépendance.
                         Merci beaucoup !
                    </span>
                    <div className="card-paypal-container">
                         {donation.map((e, k) => {
                              return (
                                   <div class="flip-card">
                                        <div class="flip-card-inner">
                                             <div class="flip-card-front">
                                                  <img key="{k}" src="{e.img}" alt="{e.alt}" style="{{" width:="" "300px",="" height:="" cursor:="" "pointer",="" }}="">
                                             </div>
                                             <div class="flip-card-back">
                                                  <p key="{k}" style="{{" textTransform:="" "uppercase",="" fontSize:="" "17px",="" }}="">
                                                       {e.name}
                                                  </p>
                                                  <span key="{k}">
                                                       {e.description}
                                                  </span>
                                                  <p key="{k}">{e.montant}</p>

                                                  <paypalscriptprovider options="{{" clientId:="" process.env="" .REACT_APP_CLIENT_ID,="" components:="" "buttons",="" currency:="" "EUR",="" }}="">
                                                       <buttonwrapper amounts="{e.price}" currency="{currency}" showSpinner="{false}"></buttonwrapper>
                                                  </paypalscriptprovider>
                                             </div>
                                        </div>
                                   </div>
                              ) ;
                         })}
                    </div>
               </div>
          </details>
     ) ;
}
</paypalbuttons>