import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";
import App from "./App";
import Home from "./components/Home";
import reportWebVitals from "./reportWebVitals";

const router = createBrowserRouter([
     {
          path: "/",
          element: <app></app>,
          children: [
               {
                    path: "/",
                    element: <home></home>,
               },
          ],
     },
]) ;

const root = ReactDOM.createRoot(document.getElementById("root")) ;
root.render(
     <react.strictmode>
          {/* <app></app> */}
          <routerprovider router="{router}"></routerprovider>
     </react.strictmode>
) ;

// Si vous voulez commencer à mesurer les performances de votre application, passez une fonction
// pour enregistrer les résultats (par exemple : reportWebVitals(console.log))
// ou envoyez-les à un point de terminaison analytique. Pour en savoir plus : https://bit.ly/CRA-vitals
reportWebVitals() ;
